<template>
  <v-container style="min-height: 100vh">
  <v-card flat>
    <v-form  @submit.prevent="getEvents()">
    <v-row class="pa-0 pt-5 pl-5 pr-5">
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          clearable
          dense
          hide-details
          v-model="titleSearch"
          label="Title"
          name="title"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          clearable
          dense
          hide-details
          v-model="organiserSearch"
          label="Organiser"
          name="organiser"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field dense clearable outlined hide-details name="venue" id="venue" v-model="venueSearch" label="Venue name"  />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field dense clearable outlined hide-details name="performerSearch" id="performerSearch" v-model="performerSearch" label="Performer"  />
      </v-col>
    </v-row>
    <v-row class="pa-0 pb-5 pl-5 pr-5">
      <v-col cols="12" sm="3">
        <v-menu
          ref="mfilterEventDate"
          v-model="mfilterEventDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                hide-details
                @click:clear="clear('filterEventDate')"
                dense
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Event Date"
                :value="parseEventDateFormat"
              />
          </template>
          <v-date-picker
            :min="today"
            range
            v-model="filterEventDate"
            @change="handeleEventDate"
          >
            <v-btn 
                text color="primary" @click="mfilterEventDate = false">Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn 
                text color="primary" @click="clearEventDate()">Clear
              </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="7" md="2" class="py-0">
          <v-col>
            <v-btn
              block
              large
              color="primary"
              class="white--text"
              type="submit"
              @click="getEvents()"
              >Search</v-btn
            >
          </v-col>
      </v-col>
    </v-row>
    <v-row class="mt-10 mt-sm-n4" justify="end">
      
    </v-row>
    </v-form>
    <v-card-title class="primary--text text-bold">Events</v-card-title>
    <v-card-text>
      
      <v-row v-if="loading">
        <v-col cols="12" sm="4" v-for="item in 9" :key="item">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" v-if="events.length > 0" sm="4" v-for="event in events" :key="event._id">
          <v-card class="ma-2" @click="setEvent(event)">
            <v-layout justify="end" v-if="event.status === 'cancelled'">
              <v-sheet
                class="mt-4"
                style='z-index:1;position:absolute;'
              >
                <v-chip label color="red" text-color="white" class="rounded-0">
                  CANCELLED
                </v-chip>
              </v-sheet>
            </v-layout>
            <v-img :aspect-ratio="16 / 9" :src="event.imageURL"></v-img>

            <v-list-item two-line>
              <v-list-item-action class="mr-4 primary--text d-grid text-bold">
                <div class="text-center">{{ event.startsAt | day }}</div>
                <div>{{ event.startsAt | month }}</div>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ event.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ event.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          
        </v-col>
        <v-col cols="12" v-if="events.length > 0">
          <template>
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="totalPage"
                @input="nextPage"
              ></v-pagination>
            </div>
          </template>
        </v-col>
        <v-col cols="12" v-if="events.length === 0">
          <v-card flat>
            <v-card-title class="justify-center primary--text ">
              We're sorry, but there were no results for your search.
            </v-card-title>

            <v-card-subtitle class="text-center">
              Please try again using an alternative search term.
            </v-card-subtitle>

            <v-img class="mx-auto my-4" width="300" height="300" src="@/assets/images/no-records.svg" />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
import { HomeService } from '@services';

export default {
  data() {
    return {
      events: [],
      allEventsData: [],
      loading: false,
      titleSearch: '',
      organiserSearch: '',
      dateSearch: '',
      venueSearch: '',
      performerSearch: '',
      mfilterEventDate: false,
      isValidEventDate: true,
      filterEventDate: [],
      today: new Date().toISOString().substr(0, 10),
      page: 1,
      totalPage: 1,
      recordsPerPage: 20,
    };
  },
  created() {
    this.getEvents();
  },
  computed: {
    parseEventDateFormat() {
      return this.$parseTransDateFormat(this.filterEventDate);
    }
  },
  methods: {
    async getEvents() {
      this.loading = true;
      const clientId = process.env.VUE_APP_THIRD_PARTY_ID;
      const searchFields = {};

      searchFields['limit'] = this.recordsPerPage;
      if(this.titleSearch) {
        searchFields['name'] = this.titleSearch;
      }
      if(this.organiserSearch) {
        searchFields['organiser'] = this.organiserSearch;
      }
      if(this.dateSearch) {
        searchFields['from'] = this.dateSearch;
      }
      if(this.venueSearch) {
        searchFields['venue'] = this.venueSearch;
      }
      if(this.performerSearch) {
        searchFields['performers'] = this.performerSearch;
      }
      if(this.filterEventDate.length) {
        searchFields['from'] = this.filterEventDate[0];
        searchFields['to'] = this.filterEventDate[1];
      }
      if(this.page) {
        searchFields['offset'] = parseInt(this.page);
      }
      let payloadData = {
        clientId: clientId,
        payload: searchFields
      }
      this.allEventsData = await HomeService.getThirdPartyEvents(payloadData);
      this.events = this.allEventsData.events;
      this.totalPage = Math.ceil(parseInt(this.allEventsData.totalCount) / parseInt(this.recordsPerPage));
      this.loading = false;
    },
    setEvent(event) {
      window.open(event.slugURL, "_blank");
    },
    handeleEventDate(val) {
      this.isValidEventDate = this.$validateDateRange(val);
      if(this.isValidEventDate) {
        this.filterEventDate = val;
      }
    },
    clearEventDate() {
      this.$refs.mfilterEventDate.save([]);
      this.filterEventDate = [];
    },
    nextPage(currentPage) {
      this.page = currentPage;
      this.getEvents();
    }
  },
};
</script>
